/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('select[name="_sfm_berth_quantity\\[\\]"] option').each(function(index){
          if(index > 0) {
            var txt = $(this).text();
            var arrTXT = txt.split(/\s{2}/);
            //console.log(arrTXT);
            $(this).text(arrTXT.join(' Berth '));
          }
        });
	      
	      $('#animated-thumbnials').lightGallery({
		      thumbnail:true
		    });
	      
				$('.banner-slideshow').slick({
				  dots: true,
				  arrows: true,
				  autoplay: true,
				  autoplaySpeed: 3000,
				  infinite: true,
				  speed: 1000,
				  fade: true,
				  cssEase: 'linear'
				});
				$('.product-gallery').slick({
					lazyLoad: 'ondemand',
				  slidesToShow: 1,
				  slidesToScroll: 1,
				  arrows: true,
				  fade: true,
				  asNavFor: '.product-nav'
				});
				$('.product-nav').slick({
					lazyLoad: 'ondemand',
				  slidesToShow: 3,
				  slidesToScroll: 1,
				  asNavFor: '.product-gallery',
				  dots: false,
				  centerMode: true,
				  centerPadding: '60px',
				  focusOnSelect: true,
				  autoplay: false,
					autoplaySpeed: 4000,
				});
				
	      $('.product-gallery').slickLightbox({
		      lazy: true,
		      background : 'rgba(0,0,0,.9)',
	      });
	      
	      $('.gallery').slickLightbox({
		      lazy: true,
		      background : 'rgba(0,0,0,.9)',
	      });
				
				
				$('.sf-field-search h4').after("<p>Do you know exactly what vehicle you're looking for?</p>");
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
